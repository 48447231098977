import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {io} from 'socket.io-client';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  socket = io('https://api-nft.zertifier.com');

  constructor(
    private http: HttpClient
  ) {
  }

  getNFC(code) {
    return new Promise((resolve, reject) => {
      this.socket.on('nfc-' + code, (data) => {
        resolve(data);
      });
    });
  }

  isAllowed(address) {
    return this.http.get('https://api-nft.zertifier.com/api/is-allowed/' + this.getSubdomain() + '/' + address);
  }

  getCompanyFromSubdomain() {

    return this.http.get('https://api-nft.zertifier.com/api/subdomain-company/' + this.getSubdomain());
  }

  getCompanyWalletFromSubdomain(address) {
    // console.log(address , 'getCompanyWalletFromSubdomain');
    if (address) {
      return this.http.get('https://api-nft.zertifier.com/api/subdomain-company-wallet/' + this.getSubdomain() + '/' + address);

    } else {
      return this.getCompanyFromSubdomain();
    }
  }


  startFreeTrial(address) {
    return this.http.post('https://api-nft.zertifier.com/api/free-trial/' + this.getSubdomain() + '/' + address, {});
  }

  getSubdomain() {
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 || domain.split('.')[0] === 'www' || domain.split('.')[0] === 'zertinft') {
      return 'zertinft';
    } else {
      return domain.split('.')[0];
    }
  }

  getEncryptedIdentity(address) {
    return this.http.get('https://api-nft.zertifier.com/api/identities/' + address);
  }

  getConfig(address) {
    return this.http.get('https://api-nft.zertifier.com/api/config/' + address);
  }

  setHashAdditionalField(hash: string, network: string, walletAddress: string, additionalFields: string) {
    return this.http.post('https://api-nft.zertifier.com/api/additionalFields', {
      hash,
      network,
      walletAddress,
      additionalFields
    });
  }

  newSmartContract(chain: string, contractAddress: string, walletAddress: string, name: string, symbol: string) {
    return this.http.post('https://api-nft.zertifier.com/api/smart-contract', {
      chain,
      contractAddress,
      walletAddress,
      name,
      symbol
    });
  }

  transferSmartContract(chain: string, contractAddress: string, walletAddress: string) {
    return this.http.post('https://api-nft.zertifier.com/api/transfer-smart-contract', {
      chain,
      contractAddress,
      walletAddress
    });
  }

  getSmartContract(chain: string, contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`https://api-nft.zertifier.com/api/smart-contract/${chain}/${contractAddress}`)
        .pipe(first())
        .subscribe((smartContract) => {
          resolve(smartContract);
        });
    });
  }

  setSmartContractTemplate(chain: string, contractAddress: string, template: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post('https://api-nft.zertifier.com/api/smart-contract-template', {
        chain,
        contractAddress,
        template
      }).pipe(first())
        .subscribe((status) => {
          resolve(status);
        });
    });
  }

  getSmartContracts(chain: string, walletAddress: string) {
    return this.http.get(`https://api-nft.zertifier.com/api/smart-contracts/${walletAddress}/${chain}`);
  }

  setEncryptedIdentity(identity) {
    return this.http.post('https://api-nft.zertifier.com/api/identities/' + identity.address, identity);
  }

  setConfig(config) {
    return this.http.post('https://api-nft.zertifier.com/api/config/' + config.address, config);
  }

  getReceivedFiles(publicKey) {
    return this.http.get('https://api-nft.zertifier.com/api/recived/files?publicKey=' + publicKey);
  }

  getSentFiles(publicKey) {
    return this.http.get('https://api-nft.zertifier.com/api/sent/files?publicKey=' + publicKey);
  }

  getHashTransaction(hash) {
    return this.http.get('https://api-nft.zertifier.com/api/hash-transaction?hash=' + hash);
  }

  setHashTransaction(hash, tx, owner, network, fileName, contract) {
    return this.http.post('https://api-nft.zertifier.com/api/hash-transaction', {
      tx,
      hash,
      owner,
      network,
      fileName,
      contract
    });
  }

  setTransaction(hash, tx, senderAddress, receiverPublicKey) {
    return this.http.post('https://api-nft.zertifier.com/api/transaction', {
      tx,
      hash,
      senderAddress,
      receiverPublicKey
    });
  }


  setFileAsSeen(hash, senderAddress, receiverPublicKey, signature) {
    return this.http.post('https://api-nft.zertifier.com/api/seen', {
      signature,
      hash,
      senderAddress,
      receiverPublicKey
    });
  }

  setSignature(hash, senderAddress, receiverPublicKey, signature) {
    return this.http.post('https://api-nft.zertifier.com/api/sign', {
      signature,
      hash,
      senderAddress,
      receiverPublicKey
    });
  }

}
