<div *ngIf="wallet" class="row">
  <div class="col-md-9">
    <h5>{{'partials.walletInfo.walletAddress' | translate }}</h5>
    <div class="input-group mb-3">
    <input [ngModel]="wallet.address" class="form-control" type="text" selectAll readonly>
    <div class="input-group-append">
                      <a [href]="walletService.currentContract?.explorer +'/address/'+ wallet.address"
                         class="btn btn-form" target="_blank">
                        <i class="fas fa-external-link-alt text-black"></i>
                      </a>
                    </div>
          </div>
      <ng-container *ngIf="wallet.privateKey">
      <h5>{{'partials.walletInfo.privateKey' | translate }}</h5>
      <app-show-hide-input-text [exportQr]="true">
        <input [ngModel]="wallet.privateKey" type="password" class="form-control"
               placeholder="Password" selectAll readonly>
      </app-show-hide-input-text>
      <ng-container *ngIf="wallet.mnemonic">
        <h5>{{'partials.walletInfo.mnemonicPhrase' | translate }}</h5>
        <app-show-hide-input-text>
          <input [ngModel]="wallet.mnemonic" type="password" class="form-control"
                 [placeholder]="'partials.walletInfo.mnemonicPhrase' | translate" selectAll readonly>
        </app-show-hide-input-text>
      </ng-container>
    </ng-container>


    <h5>{{'partials.walletInfo.balance' | translate }}</h5>
    <input
      [ngModel]="(walletService?.balance ? walletService?.balance: '0.00' )+ ' '+walletService.network.symbol.toUpperCase()"
      class="form-control mb-3" type="text" selectAll readonly>
  </div>

  <div class="col-md-3 mb-3">
    <h5 class="text-center">{{'partials.walletInfo.walletAddress' | translate }}</h5>
    <qrcode [qrdata]="wallet.address" [level]="'L'" [elementType]="'img'" [margin]="0"></qrcode>

  </div>
</div>
<app-download-keystore *ngIf="wallet && decodePassword" [wallet]="wallet"
                       [password]="decodePassword"></app-download-keystore>
<a *ngIf="wallet && wallet.privateKey" [routerLink]="['/paper-wallet', wallet.address, wallet.privateKey]"
   target="_blank" class="btn btn-primary">{{'partials.walletInfo.print' | translate }}</a>
