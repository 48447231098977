import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ClipboardService} from 'ngx-clipboard';
import * as CryptoJS from 'crypto-js';

declare var $: any;
declare const NDEFReader: any;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private clipboardService: ClipboardService
  ) {
    $(document).on('click', '.copy-data', (ev: any) => {
      this.copyText($(ev.currentTarget).data('text'));
      const originalTitle = $(ev.currentTarget).attr('data-original-title');
      $(ev.currentTarget).attr('data-original-title', 'Copied!').tooltip('show');
      setTimeout((event, title) => {
        $(event.currentTarget).attr('data-original-title', title).tooltip('hide');
      }, 2000, ev, originalTitle);
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  textShortener(text: string, length: number = 6): string {
    if (text.length > length * 2) {
      return text.substr(0, length) + '...' + text.substr(-length);
    } else {
      return text;
    }
  }

  copyText(text: string) {
    this.clipboardService.copyFromContent(text);
  }

  launchCopyTooltip() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover'
    });
    $('.copy-data').click((ev: any) => {
      this.copyText($(ev.currentTarget).data('text'));
      const originalTitle = $(ev.currentTarget).attr('data-original-title');
      $(ev.currentTarget).attr('data-original-title', 'Copied!').tooltip('show');
      setTimeout(() => {
        $(ev.currentTarget).attr('data-original-title', originalTitle).tooltip('hide');
      }, 2000);
    });
  }

  byteLength(str) {
    // returns the byte length of an utf8 string
    let s = str.length;
    for (let i = str.length - 1; i >= 0; i--) {
      const code = str.charCodeAt(i);
      if (code > 0x7f && code <= 0x7ff) {
        s++;
      } else if (code > 0x7ff && code <= 0xffff) {
        s += 2;
      }
      if (code >= 0xDC00 && code <= 0xDFFF) {
        i--;
      }
    }
    return s;
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  downloadFile(filename, href) {
    const tempLink = document.createElement('a');
    tempLink.setAttribute('href', href);
    tempLink.setAttribute('target', '_blank');

    if (filename) {
      tempLink.setAttribute('download', filename);
    }

    tempLink.style.display = 'none';
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  }

  encrypt(data, password) {
    return CryptoJS.AES.encrypt(data, password);
  }

  decrypt(data, password) {
    const bytes = CryptoJS.AES.decrypt(data, password);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  scanNfc(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const ndef = new NDEFReader();
        await ndef.scan();
        // $('#result').append('> Scan started<br>');

        ndef.addEventListener('readingerror', (e: any) => {
          console.log(e, 'e');
          // $('#result').append('Argh! Cannot read data from the NFC tag. Try another one?<br>');
          reject('Argh! Cannot read data from the NFC tag. Try another one?');
          console.log('Argh! Cannot read data from the NFC tag. Try another one?');
        });

        ndef.addEventListener('reading', ({message, serialNumber}: any) => {
          // $('#result').append('----------------------------<br>');
          const decoder = new TextDecoder();
          for (let i = 0; i < message.records.length; i++) {
            const record = message.records[i];
            // $('#result').append(decoder.decode(record.data) + '<br>');
            if (record.data) {
              console.log(decoder.decode(record.data), 'decoder.decode(record.data)');
            }
            // console.log(record.getData(),'record.getData()' );
          }
          resolve(serialNumber);
          // $('#result').append(`> Serial Number: ${serialNumber}<br>`);
          console.log(`> Serial Number: ${serialNumber}`);
          // $('#result').append(`> Records: (${message.records.length})<br>`);
          console.log(`> Records: (${message.records.length})<br>`);
        });
      } catch (error) {
        // $('#result').append('Argh! ' + error + '<br>');
        reject('Argh! ' + error);
        console.log('Argh! ' + error);
      }
    });
  }
}
