<div class="card h-100 mb-3">
  <div class="text-center">
    <h4>{{'partials.signMessageBox.title' | translate}}</h4>
  </div>
  <div class="card-body">
    <div class="text-black card-alert align-items-center d-flex justify-content-center">
      <i class="h1 animated zoomIn infinite slower fas fa-circle"></i>
    </div>
<div class="w-100 text-center">
  <button style="width: 200px" (click)="sign()" class="btn btn-primary">
    {{'partials.signMessageBox.sign' | translate}}
  </button>
</div>

  </div>
</div>
