import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'joinArray'
})
export class JoinArrayPipe implements PipeTransform {

  transform(data: string[], delimiter: string = ', ') {
    return data.filter(Boolean).join(delimiter);
}
}
